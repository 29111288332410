body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: gray;
}

code {
  font-family: Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3 {
  margin: 0.3em 0 0.1em;
  color: black;
}

h1,
h2,
h3 {
  color: black;
}

h1 {
  margin: 0 0 0.1em 0;
  font-size: 1.4em;
}

h2 {
  margin: 0.3em 0 0 0;
  font-size: 1.3em;
}

h3 {
  margin: 0.3em 0 0 0;
  font-size: 1.2em;
}

.nav {
  position: fixed;
  top: 0;
  width: 180px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-right: 1px solid lightgray;
  background-color: white;
  z-index: 1;
}

.nav-header {
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin: 10px 10px 5px;
}

.nav-list {
  display: flex;
  flex-direction: column;
}

.nav-dropdown {
  display: none;
}

.nav-item {
  color: gray;
  text-decoration: none;
  max-width: 140px;
  margin: 2px 10px 2px 15px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav-item.active,
.nav-item:hover {
  color: black;
}

.nav-item.active {
  font-weight: bold;
}

.body {
  margin: 10px 40px 50px 200px;
}

.section {
  position: relative;
}

.section>.anchor {
  position: absolute;
  top: 0;
}

.section:first-child>.anchor {
  top: -20px;
}

.section-container {
  display: flex;
  margin-left: 10px;
}

.section-left {
  width: 50%;
  margin-right: 40px;
}

.description {
  margin: 5px;
}

.label {
  display: flex;
  align-items: flex-end;
}

.label-name {
  color: #1e1e1e;
  margin-right: 5px;
  font-weight: bold;
}

.label-details {
  font-size: 0.8em;
  line-height: 19px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  margin: 10px 0;
}

.section-right {
  width: 50%;
}

.code-example {
  background: #3c3c3c;
  border-radius: 8px;
}

.code-example-header {
  background: #1e1e1e;
  padding: 4px 13px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #cccccc;
  margin: 0;
}

.code-example-body {
  color: #bababa;
  padding: 4px 13px;
  margin: 0;
  letter-spacing: 0.5;
}

.code-example-body>a {
  color: #bababa;
  text-decoration: none;
}

.response-example {
  overflow: hidden;
  margin-top: 10px;
}

.response-example-header {
  padding: 4px 13px;
  background: #cccccc;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #444444;
  margin: 0;
}

.response-example-body {
  padding: 4px 13px;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #cccccc;
  color: #444444;
  margin: 0;
}

.json-string {
  color: #098658;
}

.json-number {
  color: #ff0000;
}

.json-bool {
  color: #0451a5;
}

.event-type {
  margin-bottom: 10px;
}

.sticky {
  position: sticky;
  top: 10px;
}

@media (max-width: 1139px) {
  .section-container {
    flex-direction: column-reverse;
  }

  .section-left {
    width: 100%;
  }

  .section-right {
    width: 100%;
    margin: 0 0 20px;
  }

  .sticky {
    position: relative;
  }
}

@media (max-width: 649px) {
  code {
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .nav {
    width: 100%;
    height: 42px;
    top: 0;
    border-right: unset;
    border-bottom: 1px solid lightgray;
    z-index: 2;
  }

  .nav-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    z-index: 2;
    background-color: white;
  }

  .nav-header {
    height: 20px;
    line-height: 20px;
  }

  .nav-dropdown {
    display: flex;
    margin: 5px 2px;
    text-align: center;
    line-height: 20px;
    border: 1px solid lightgray;
    border-radius: 20px;
    padding: 5px 8px;
    height: 20px;
    cursor: pointer;
  }

  a.nav-dropdown {
    color: grey;
    text-decoration: none;
  }

  .nav-dropdown-text {
    padding-right: 8px;
  }

  .nav-dropdown-arrow {
    transition: transform 0.4s;
  }

  .nav-dropdown-arrow.open {
    transform: rotate(90deg);
  }

  .nav-list {
    position: fixed;
    top: 42px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid lightgray;
    z-index: 1;
    display: none;
  }

  .nav-list.open {
    display: flex;
  }

  .nav-item {
    margin: 5px 2px;
    text-align: center;
    line-height: 20px;
    padding: 5px;
    height: 20px;
  }

  .body {
    margin: 50px 10px 10px;
  }

  .section:first-child>.anchor {
    top: -66px;
  }

  .section>.anchor {
    top: -46px;
  }
}

@media (max-width: 319px) {
  .nav-dropdown {
    display: none;
  }

  .nav-list {
    display: none;
  }
}